import useSWR from 'swr';
import useApplication from '../../butterfly/useApplication/useApplication';
import { orderBy } from 'lodash';
import { CustomerData, LoanDetails, Offer } from 'merchant-api/types';

export interface UseDetailedOffersProps {
  isLoadingDetailedOffers: boolean;
  isValidating: boolean;
  offers: Offer[];
  tlOffers: Offer[];
  selectedOffer?: Offer;
  previousLoan?: LoanDetails;
  customerData: CustomerData;
  isErrorOffers: any;
  isPartnerChannel?: Boolean;
  remainingPrincipal?: number;
  apiPath?: string;
  experienceType?: 'configurable' | string;
  response: any;
}

export function useDetailedOffers(): UseDetailedOffersProps {
  const apiUrl = import.meta.env['VITE_OFFER_ORCHESTRATOR_URL'];
  const {
    applicationData,
    isLoadingApplication,
    isErrorApplication,
    isPartnerChannel,
  } = useApplication();
  const apiPath = `${apiUrl}/v2/detailedOffers?applicationId=${applicationData?.id}`;
  const { data, error, isLoading, isValidating } = useSWR(
    applicationData?.id
      ? `${apiUrl}/v2/detailedOffers?applicationId=${applicationData.id}`
      : null,
    { revalidateOnFocus: false, revalidateOnReconnect: false },
  );

  const previousLoan = data?.loanDetails;
  const customerData = data?.customerData;

  const filterProductOffer = (product: string, offers: any[]) =>
    offers
      ?.filter(
        (offer) =>
          offer?.product === product && offer?.presentationStatus === 'ACTIVE',
      )
      .map((offer) =>
        offer.pricedDetails.paymentFrequency === 'WEEKLY'
          ? { ...offer, pricedDetails: {...offer.pricedDetails, paymentFrequency: 'Weekly' }}
          : offer,
      )
      .map((offer) =>
        offer.pricedDetails.paymentFrequency === 'DAILY'
          ? { ...offer, pricedDetails: {...offer.pricedDetails,  paymentFrequency: 'Daily' }}
          : offer,
      );
  const tlArray = filterProductOffer(
    'TermLoan',
    data?.applicationOffer?.offers,
  );
  const filteredTLArray = tlArray?.filter(
    (offer) => !offer.prePaymentDiscountRelatedOfferIds?.hasOwnProperty('100'),
  );
  const orderedTLOffers = orderBy(
    filteredTLArray,
    [(offer) => offer.prePaymentDiscountPct === 100, 'loanAmount', 'term'],
    ['desc', 'desc', 'desc'],
  );

  const selectedOffer = data
    ? data?.applicationOffer?.offers.filter(
        (o) => o.offerId === data?.applicationOffer.selectedOffer,
      )[0] || null
    : undefined;

  return {
    isValidating: isValidating,
    isLoadingDetailedOffers: isLoading || isLoadingApplication,
    remainingPrincipal: previousLoan?.remainingPrincipal,
    offers: data?.applicationOffer?.offers,
    experienceType: data?.applicationOffer?.experienceType,
    tlOffers: orderedTLOffers,
    selectedOffer: selectedOffer,
    previousLoan: previousLoan,
    customerData: customerData,
    isErrorOffers: error || isErrorApplication,
    isPartnerChannel: isPartnerChannel,
    apiPath: apiPath,
    response: data,
  };
}

export default useDetailedOffers;

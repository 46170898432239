import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Range, MoneyInput } from '@odmonorepo/core-components';

interface InputSliderComboProps {
  min?: number;
  max?: number;
  initialValue?: number;
  onChange?: Function;
  onInput?: Function;
  step?: number;
  inputStep?: number;
  rangeStep?: number;
  row?: boolean;
  roundNum?: boolean;
  upperErrorMessage?: string;
  lowerErrorMessage?: string;
}

const defaultChoice = (min, max) => Math.round((75 * (max - min)) / 100 + min);

export const InputSliderCombo = ({
  min = 5100,
  max = 10000000,
  step = 100,
  inputStep,
  rangeStep,
  initialValue,
  lowerErrorMessage,
  upperErrorMessage,
  onChange = () => {},
  onInput = () => {},
  row = false,
}: InputSliderComboProps) => {
  const [value, setValue] = useState<any>(initialValue ? initialValue : defaultChoice(min, max));
  useEffect(() => {
    onChange(value);
  }, [value]);
  return (
    <>
    <Row>
      <Col sm={row ? 12 : 8}>
        <Range
          min={min}
          max={max}
          step={rangeStep ? rangeStep : step}
          thumb
          showMinMaxLabel
          value={value < min ? min : value > max ? max : value}
          onChange={v => {
            setValue(v);
          }}
        />
      </Col>
      <Col className={`mt-3 mt-sm-0 ${row && 'pt-3'}`}>
        <MoneyInput
          min={min}
          max={max}
          upperErrorMessage={upperErrorMessage}
          lowerErrorMessage={lowerErrorMessage}
          value={value < min ? min : value > max ? max : value}
          round={inputStep ? inputStep : step}
          onInput={() => onInput}
          onChange={setValue}
        />
      </Col>
    </Row>
    </>
  );
};

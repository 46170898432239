import { default as Analytics, AnalyticsInstance } from 'analytics';
import fullStoryPlugin from '@analytics/fullstory';
import mixpanelPlugin from '@analytics/mixpanel';
import { adobeAnalyticsPlugin, datadogRumPlugin } from '@odmonorepo/odfunctions';
import tmAnalytics from 'libs/odfunctions/src/lib/tmProfiling.js';

const IS_DEV = import.meta.env.NODE_ENV === 'development';
const serviceName = 'ondeck_merchant_ux';

export const analytics: AnalyticsInstance = Analytics({
  app: serviceName,
  debug: IS_DEV,
  plugins: [
    fullStoryPlugin({
      org: import.meta.env.VITE_FULLSTORY_TOKEN,
    }),
    mixpanelPlugin({
      token: import.meta.env.VITE_MIXPANEL_TOKEN,
    }),
    datadogRumPlugin({
      applicationId: import.meta.env.VITE_DATADOG_APP_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      serviceName: serviceName,
    }),
    adobeAnalyticsPlugin(),
    tmAnalytics()
  ],
});

export default analytics;

import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import useDetailedOffers from "../useDetailedOffers/useDetailedOffers";
import { useApplication } from "merchant-api/butterfly";

export interface PricingResponse {
  lineAmount: number;
  trueCentsOnDollar: number;
  trueAPR: number;
  paymentFrequency: string;
  numberOfPayments: number;
  payment: number;
  disbursementAmount: number;
  totalAmountPaidBack: number;
  lender: string;
  modelId: string;
  loanOriginationFee: number;
  averageMonthlyPayment: number;
  term: number;
  totalLoanCost: number;
  monthlyFee: number;
  ppd?: 100 | 25;
}

export interface PricingRequest {
  applicationType: "RENEWAL" | "NEW";
  customerData: {
    businessCountry: string;
    businessState: string;
    legalEntity: string;
  };
  loanSpecifics: [
    {
      centsOnDollar: number;
      collectionType: "ACH";
      line: number;
      oFeePercentage: number;
      paymentFrequency: string;
      product: string;
      productType: string;
      term: number;
    }
  ];
}

export interface SendOfferProps {
  offerId: string;
  loanAmount: number;
  paymentFrequency: 'Weekly' | 'Daily';
}

export interface GetPricingProps {
  response: any;
  getPricingSending: boolean;
  getPricingError: string;
  getPricing: (args: SendOfferProps) => Promise<PricingResponse>;
}

export function getPricingDetails(): GetPricingProps {
  const apiUrl = import.meta.env['VITE_OFFER_ORCHESTRATOR_URL']
  const {fetcher, mutate} = useSWRConfig();
  const {customerData, offers, apiPath} = useDetailedOffers();
  const {applicationData} = useApplication();

  async function sendRequest(url, {arg}: {arg: SendOfferProps}) {
    const selectedOffer = offers?.filter(o => o.offerId === arg.offerId)[0];
    const pricingRequest: PricingRequest = {
      applicationType: applicationData?.type,
      customerData: customerData,
      loanSpecifics: [
        {
          centsOnDollar: selectedOffer?.centsOnDollar,
          collectionType: "ACH",
          line: arg?.loanAmount,
          oFeePercentage: selectedOffer?.originationFeePct,
          paymentFrequency: arg?.paymentFrequency,
          product: selectedOffer?.product,
          productType: selectedOffer?.product,
          term: selectedOffer?.term,
        }
      ]
    };  
    mutate(apiPath);
    const data = await fetcher!(url, {method: 'POST', body: JSON.stringify({...pricingRequest})}) as PricingResponse;
    const ppd = selectedOffer?.prePaymentDiscountPct === 100 ? 100 : 25;
    return {...data, ppd: ppd} as PricingResponse;
  };

  const {data, error, trigger, isMutating} = useSWRMutation(`${apiUrl}/v1/pricing`, sendRequest);

  return {
    response: data,
    getPricingSending: isMutating,
    getPricingError: error,
    getPricing: trigger
  }
}

export default getPricingDetails;
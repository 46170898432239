import { datadogRum } from '@datadog/browser-rum';


interface userConfig {
  applicationId?: string;
  clientToken?: string;
  serviceName?: string;
}

const RELEASE_VERSION = import.meta.env['VITE_RELEASE_VERSION'] || 'unknown';

export function datadogRumPlugin(userConfig: userConfig) {
  return {
    name: 'datadog-rum',
    initialize: ({ config }) => {
      datadogRum.init({
        applicationId: userConfig.applicationId!,
        clientToken: userConfig.clientToken!,
        site: 'datadoghq.com',
        service: userConfig.serviceName!,
        env: import.meta.env['NODE_ENV'],
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        trackSessionAcrossSubdomains: true,
        version: RELEASE_VERSION,
        allowedTracingUrls: [
          import.meta.env['VITE_BUTTERFLY_URL'],
          import.meta.env['VITE_CHECKOUT_ORCHESTRATOR_URL'],
          import.meta.env['VITE_OFFER_ORCHESTRATOR_URL'],
          import.meta.env['VITE_SPICEROAD_URL']
        ]
      })
    },
    page: ({ payload }) => {
      datadogRum.startView(payload.properties.url);
    },
    track: ({ payload }) => {
      datadogRum.addAction(payload.event, payload.properties);
    },
    identify: ({ payload }) => {
      datadogRum.setUser({
        id: payload.userId,
        name: payload.traits.name,
        email: payload.traits.email,
      });
    }
  };
}


export default datadogRumPlugin;

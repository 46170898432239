import {
  Card,
  FormattedMessageDiv,
  FormattedNumberDiv,
} from '@odmonorepo/core-components';
import { combineClassNames } from '@odmonorepo/odfunctions';
import { Badge, Row, Col, Stack } from 'react-bootstrap';

interface PPDOptionProps {
  discount: number;
  exampleInterest: number;
  selected?: boolean;
}

export const PPDOption = ({
  discount,
  exampleInterest,
  selected,
}: PPDOptionProps) => (
  <Card
    className={combineClassNames(
      'shadow-none border py-5 px-3',
      selected ? 'border-primary' : '',
    )}
    bg={selected ? 'light-gray' : 'white'}
    role="button"
    bodyClassName='p-0'
  >
    <Stack direction="horizontal" >
      <Stack>
        <input
          type="radio"
          checked={selected}
          style={{ pointerEvents: 'none' }}
          className="me-4 mt-2"
        />
      </Stack>
      <Stack>
        <Row>
          <Col>
            <FormattedMessageDiv
              className="fw-bold mb-4"
              id={`prepayment.select.${discount}.title`}
            />
            <FormattedMessageDiv
              className="text-muted"
              id={`prepayment.select.${discount}.body`}
            />
            <hr className="border border-dark" />
            <div className="mb-2">
              <Badge pill={true} className="bg-primary bg-opacity-25 text-primary">
                <FormattedMessageDiv id='prepayment.select.example' />
              </Badge>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormattedMessageDiv
              id="prepayment.select.header.remainingInterest"
              className="small fw-bold"
            />
            <FormattedNumberDiv
              value={exampleInterest}
              maximumFractionDigits={0}
              style="currency"
              currency="USD"
              className="fs-4"
            />
          </Col>
          <Col>
            <FormattedMessageDiv
              id="prepayment.select.header.interestOwed"
              className="small fw-bold"
            />
            <FormattedNumberDiv
              value={exampleInterest - 10 * discount}
              maximumFractionDigits={0}
              style="currency"
              currency="USD"
              className="fs-4"
            />
          </Col>
          <Col>
            <FormattedMessageDiv
              id="prepayment.select.header.interestWaived"
              className="small fw-bold"
            />
            <FormattedNumberDiv
              value={10 * discount}
              maximumFractionDigits={0}
              style="currency"
              currency="USD"
              className="fs-4"
            />
          </Col>
        </Row>
      </Stack>
    </Stack>
  </Card>
);

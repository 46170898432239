import {
  ErrorPage,
  FormattedNumberDiv,
  LoadingAnimation,
  WizardForm,
} from '@odmonorepo/core-components';
import { FullConfig } from '../../Features/OfferConfiguration/Components/FullConfig/FullConfig';
import SimpleConfig from '../../Features/OfferConfiguration/Components/SimpleConfig/SimpleConfig';
import PPDOptions from '../../Features/OfferConfiguration/Components/PPD/PPDOptions';
import { Card, Stack } from 'react-bootstrap';
import { ConfigStepCard } from '../../Features/OfferConfiguration/Cards/ConfigStepCard';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { maxLoanAmount } from '../../Features/OfferConfiguration/minMaxLoanAmounts';
import { useDetailedOffers } from 'merchant-api/offer';
import { PricedDetails } from 'merchant-api/types';
import { atom, useAtom, useSetAtom } from 'jotai';

export enum OfferConfigStep {
  SIMPLE_CONFIG,
  FULL_CONFIG,
  PPD_OPTION,
}

const schema = yup.object().shape({
  offerId: yup.string(),
  paymentFrequency: yup.string(),
  loanAmount: yup.number(),
});

export const pricedDetails = atom<PricedDetails>()
export const disablePreview = atom(false)
export const remainingBalance = atom(0)

export const OfferConfig = () => {
  const { tlOffers, remainingPrincipal, isLoadingDetailedOffers, isErrorOffers } = useDetailedOffers();
  const [pricing, setPriced] = useAtom(pricedDetails)
  const [remaining, setRemaining] = useAtom(remainingBalance)

  if (isLoadingDetailedOffers) return <Stack><LoadingAnimation className="mx-auto mt-7"/></Stack>
  if (isErrorOffers) return <Stack><ErrorPage /></Stack>

  if (tlOffers) {
    !pricing && setPriced({...tlOffers[0].pricedDetails, ppd: tlOffers[0].prePaymentDiscountPct === 100 ? 100 : 25})
    !remaining && remainingPrincipal && setRemaining(remainingPrincipal)
  }

  const defaultValues = {
    offer: {
      offerId: tlOffers[0].offerId,
      loanAmount: tlOffers[0].pricedDetails.lineAmount,
      paymentFrequency: tlOffers[0].pricedDetails.paymentFrequency,
    }
  };

  return (
    <Stack gap={5}>
      <h4 className="text-secondary">
        <FormattedNumberDiv
          value={maxLoanAmount(tlOffers)}
          style={'currency'}
          currency="USD"
          minimumFractionDigits={0}
          prefix={<FormattedMessage id="offerConfig.title.prefix" />}
          suffix="."
        />
      </h4>
      <Card>
        <WizardForm schema={schema} defaultValues={defaultValues}>
          <ConfigStepCard children={<SimpleConfig />}/>
          <ConfigStepCard children={<FullConfig />}/>
          <ConfigStepCard children={<PPDOptions />}/>
        </WizardForm>
      </Card>
    </Stack>
  );
};

export default OfferConfig;

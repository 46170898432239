import { ToggleElement } from '@odmonorepo/core-components';
import { combineClassNames } from '@odmonorepo/odfunctions';
import { useSetAtom } from 'jotai';
import { Stack, ButtonGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { disablePreview } from '../../OfferConfig';
import { emitWarning } from 'process';

export const CalculatorPaymentFrequency = () => {
  const setDisabled = useSetAtom(disablePreview);
  return (
    <Stack>
      <div className="fw-bold mb-2">
        <FormattedMessage id="calculator.paymentFreqChoices" />
      </div>
      <Controller
        name="offer.paymentFrequency"
        render={({ field: { onChange, value, name } }) => (
          <ButtonGroup>
            {['Daily', 'Weekly'].map((f) => (
              <ToggleElement
                value={f}
                name={name}
                onChange={(e) => (setDisabled(true), onChange(e.target.value))}
                className={combineClassNames(
                  'btn btn-outline-accent w-100',
                  value === f ? 'fw-bold' : '',
                )}
                checked={value === f}
              >
                {f}
              </ToggleElement>
            ))}
          </ButtonGroup>
        )}
      />
    </Stack>
  );
};

import React, { useEffect, useState } from 'react';
import { InputGroup, Stack } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import { NumericFormatProps } from 'react-number-format/types/types';

type MoneyInputProps = Omit<JSX.IntrinsicElements['div'], 'onChange'> & NumericFormatProps & {
  min?: number;
  max?: number;
  value?: number;
  onChange?: Function;
  onNumberInput?: Function;
  currency?: string;
  round?: number;
  upperErrorMessage?: string;
  lowerErrorMessage?: string;
}

export const MoneyInput = ({
  min = 0,
  max = 10000000,
  value = 0,
  currency = '$',
  onChange = () => {},
  onNumberInput = () => {},
  round,
  upperErrorMessage,
  lowerErrorMessage,
  ...props
}: MoneyInputProps) => {
  const [localValue, setLocalValue] = useState<number>(value);
  const [errorMsg, setErrorMsg] = useState<string>();

  const checkBounds = value => {
    if (value > max) {
      setLocalValue(max);
      onChange(max as any);
      setErrorMsg(upperErrorMessage)
      return;
    } else if (value < min) {
      setLocalValue(min);
      onChange(min as any);
      setErrorMsg(lowerErrorMessage)
      return;
    } else if (value !== max) {
       setErrorMsg(undefined)
    }
    value = round && round !== 0 ? Math.round(value/round) * round : value;
    value !== localValue && setLocalValue(value);
    onChange(value);
  };

  useEffect(() => {
    checkBounds(value);
  }, [value]);

  return (
    <Stack>
      <InputGroup className="mb-1">
        <InputGroup.Text className="fs-3 px-4 bg-secondary text-white border-secondary">{currency}</InputGroup.Text>
        <NumericFormat
          className="form-control"
          thousandSeparator={true}
          value={localValue}
          data-testid="MoneyInput"
          onValueChange={v => setLocalValue(parseFloat(v.value))}
          onBlur={v => checkBounds(localValue)}
          onInput={e => (setErrorMsg(undefined), onNumberInput())}
          {...props}
        />
      </InputGroup>
      {errorMsg && <div className='text-danger small text-start'>{errorMsg}</div>}
    </Stack>
  );
};

import { Stack } from 'react-bootstrap';
import {
  FormattedMessageDiv,
  Button,
  LoadingAnimation,
  ErrorPage,
} from '@odmonorepo/core-components';
import SelectOfferCard from '../../Cards/SelectOfferCard';
import { useWizard } from 'react-use-wizard';
import { Controller, useFormContext } from 'react-hook-form';
import { ToggleElement } from "@odmonorepo/core-components";
import { useDetailedOffers } from 'merchant-api/offer';
import { OfferConfigStep, pricedDetails } from '../../OfferConfig';
import { Offer } from 'merchant-api/types';
import { useSetAtom } from 'jotai';

export const SimpleConfig = () => {
  const { goToStep } = useWizard();
  const { watch } = useFormContext();
  const { tlOffers, isLoadingDetailedOffers, isErrorOffers } = useDetailedOffers();
  const setPricing = useSetAtom(pricedDetails)
  const offer = watch("offer")
 
  if (isLoadingDetailedOffers) return <Stack><LoadingAnimation className="mx-auto" /></Stack>
  if (isErrorOffers) return <Stack><ErrorPage /></Stack>

  const handleChange = (id:string, o:Offer, onChange: (any) => void) => {
    const ppd = o.prePaymentDiscountPct === 100 ? 100 : 25
    setPricing({...o.pricedDetails, ppd});
    onChange({
      offerId: id,
      loanAmount: o.pricedDetails.lineAmount,
      paymentFrequency: o.pricedDetails.paymentFrequency,
    })
  }

  return (
      <Stack direction='vertical' className='flex-none px-4 py-4 py-md-5 pe-md-2 ps-md-6'>
        <FormattedMessageDiv
          className="fw-bold pb-4"
          id="offerConfig.simple.selectOffer.heading"
        />
        <Controller
          name="offer"
          defaultValue={{offerId: tlOffers[0].offerId, loanAmount: tlOffers[0].pricedDetails.lineAmount, paymentFrequency: tlOffers[0].pricedDetails.paymentFrequency}}
          render={({ field: { onChange, name } }) => (
            <Stack direction='vertical' gap={3}>
            { tlOffers.slice(0, 3).map((o) => (
              <ToggleElement value={o.offerId} onChange={(e) => handleChange(e.target.value, o, onChange)} name={name}>
                <SelectOfferCard offer={o} selected={offer.offerId === o.offerId && offer.loanAmount === o.pricedDetails.lineAmount}/>
              </ToggleElement>
            ))
            }
            </Stack>
          )}
        />
          <Button
            variant="link"
            onClick={() => goToStep(OfferConfigStep.FULL_CONFIG)}
          >
            <FormattedMessageDiv
              className="fs-7"
              id="offerConfig.simple.selectOffer.customize"
            />
          </Button>
      </Stack>
  );
};

export default SimpleConfig;

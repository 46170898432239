import { Col, Row } from 'react-bootstrap';
import OfferPreview from '../Components/OfferPreview/OfferPreview';
import { Card } from '@odmonorepo/core-components';
import { useAtomValue } from 'jotai';
import { pricedDetails } from '../OfferConfig';

export const ConfigStepCard = ({ children }) => {
  const pricing = useAtomValue(pricedDetails)
  return (
    <Card bodyClassName='p-0'>
        <Row sm={1} lg={ pricing ? 2 : 1}>
          <Col lg={7}>{children}</Col>
          {pricing && <Col lg={5}><OfferPreview /></Col>}
        </Row>
    </Card>
  );
};

import { PricedDetails } from 'merchant-api/types';
import { PricingResponse } from '../getPricingDetails/getPricingDetails';
import {OfferSelectRequest} from './selectOffer'

export function pricingToOfferSelect(pricingResponse: PricingResponse | PricedDetails): OfferSelectRequest {
  return {
    apr: pricingResponse.trueAPR,
    centsOnDollar: pricingResponse.trueCentsOnDollar,
    disbursementAmount: pricingResponse.disbursementAmount,
    installmentAmount: pricingResponse.payment,
    lender: pricingResponse.lender,
    lineAmount: pricingResponse.lineAmount,
    numberOfPayments: pricingResponse.numberOfPayments,
    ofeeAmount: pricingResponse.loanOriginationFee,
    paymentFrequency: pricingResponse.paymentFrequency,
    totalDrawCost: pricingResponse.totalLoanCost,
    totalPaybackAmount: pricingResponse.totalAmountPaidBack,
    unifiedPricingModelId: pricingResponse.modelId
  };
}

import React, { useEffect } from 'react';
import { Stack } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { ToggleElement } from "@odmonorepo/core-components";
import { PPDOption } from './PPDOption';
import { Button, FormattedMessageDiv } from '@odmonorepo/core-components';
import { useWizard } from 'react-use-wizard';
import { getPricingDetails, SendOfferProps, useDetailedOffers } from 'merchant-api/offer';
import { disablePreview, OfferConfigStep, pricedDetails } from '../../OfferConfig';
import { useSetAtom } from 'jotai';

export const PPDOptions = () => {
  const { watch } = useFormContext();
  const { goToStep } = useWizard();
  const setPricing = useSetAtom(pricedDetails);
  const setDisabled = useSetAtom(disablePreview)
  const { getPricingError, getPricing, getPricingSending} = getPricingDetails();
  const { offers } = useDetailedOffers();
  const exampleInterest = 1000;
  const data = watch("offer") as SendOfferProps

  useEffect(() => {
    setDisabled(getPricingSending)
  }, [getPricingSending]);

  if (getPricingError) throw new Error('Error getting pricing');

  const ppdToOfferId = (ppd: number, value) => {
    const selectedOffer = offers.filter(o => o.offerId === value)[0];
    const ppdNoNulls = selectedOffer?.prePaymentDiscountPct === 100 ? 100 : 25;
    return ppdNoNulls === ppd
      ? selectedOffer.offerId
      : selectedOffer?.prePaymentDiscountRelatedOfferIds[ppd.toString()];
  };

  return (
    <Stack className="shadow-none w-100 px-4 pt-4 pt-md-5 pe-md-2 ps-md-6">
      <Controller
        name="offer.offerId"
        render={({ field: { onChange, name, value } }) => (
          <Stack direction="vertical" gap={3}>
            {[100, 25].map((discount) => {
              const offerId = ppdToOfferId(discount, value)
              return <ToggleElement 
                value={offerId}
                onChange={onChange}
                name={name}
                onClick={() => getPricing({...data, offerId: offerId}).then(res =>(setPricing({...res, ppd: Number(discount)})))}
               >
                <PPDOption
                  discount={discount}
                  exampleInterest={exampleInterest}
                  selected={value === offerId || watch("offer.offerId") === offerId}
                />
              </ToggleElement>
            })}
          </Stack>
        )}
      />
      <Button
        variant="link"
        onClick={() => {
          goToStep(OfferConfigStep.SIMPLE_CONFIG);
        }}
      >
        <FormattedMessageDiv
          className="fs-7 m-2"
          id="prepayment.link.selectDifferentOffer"
        />
      </Button>
    </Stack>
  );
};

export default PPDOptions;

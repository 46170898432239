import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import { Offer, SelectResponse } from "merchant-api/types";
import { PricingResponse } from "../getPricingDetails/getPricingDetails";
import { pricingToOfferSelect } from "./helper";
import useDetailedOffers from "../useDetailedOffers/useDetailedOffers";

export interface OfferSelectRequest {
  apr: number;
  centsOnDollar: number;
  disbursementAmount: number;
  installmentAmount: number;
  lender: string;
  lineAmount: number;
  numberOfPayments: number;
  ofeeAmount: number;
  paymentFrequency: string;
  totalDrawCost: number;
  totalPaybackAmount: number;
  unifiedPricingModelId: string;
}

export interface SelectOfferProps {
  response: any;
  selecting: boolean;
  selectionError: string;
  select: (args: PricingResponse) => Promise<SelectResponse>;
}


export function selectOffer(offerId: string): SelectOfferProps {
  const apiUrl = import.meta.env['VITE_OFFER_ORCHESTRATOR_URL']
  const {fetcher, mutate} = useSWRConfig();
  const { apiPath, response} = useDetailedOffers();

  async function sendRequest(url, {arg}: {arg: PricingResponse}) {
    const data = await fetcher!(url, {method: 'POST', body: JSON.stringify(pricingToOfferSelect(arg))}) as SelectResponse;
    await mutate(apiPath, { ...response, applicationOffer: {...response.applicationOffer, selectedOffer: data?.selectedOffer }})
    return data;
  }

  const {data, error, trigger, isMutating} = useSWRMutation(`${apiUrl}/v2/offers/${offerId}/select`, sendRequest);

  return {
    response: data,
    selecting: isMutating,
    selectionError: error,
    select: trigger
  }
}

export default selectOffer;
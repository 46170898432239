import { FloatingLabelProps, FloatingLabel, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { getError } from './FormHelpers';
import { useWizardFormContext } from '@odmonorepo/core-components';
import { ReactNode } from 'react';

interface FormInputProps extends Omit<FloatingLabelProps, 'label'> {
  name?: string;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
  useYupErrors?: boolean;
  helperText?: ReactNode;
}
export const FormInput = ({name = '', required = true, type = 'text',label, disabled = false, useYupErrors = false, helperText, ...props}: FormInputProps) => {
  const { register, formState, clearErrors } = useFormContext()
  const onInput = (e: any) => e.target.value && clearErrors(name);
  const { formName } = useWizardFormContext() || { formName: ''};;
  
  return (
    <FloatingLabel controlId={name} {...props} label={label ? label : <FormattedMessage id={'formInput.' + name} />}>
      <Form.Control
        type={type}
        onInput={onInput}
        required={required}
        disabled={disabled}
        {...(register(name as any) || {})}
        isInvalid={!!getError(name)}
        form={formName || ''}
      />
      {<Form.Control.Feedback type="invalid">{useYupErrors ? getError(name)?.message : <FormattedMessage id={'formInput.' + name + '.error'} />}</Form.Control.Feedback>}
      {helperText && <div className="text-gray fs-8">{typeof helperText === 'boolean' ? <FormattedMessage id={'formInput.' + name + '.helperText'} />: helperText}</div>}
    </FloatingLabel>
  );
};


export default FormInput;
